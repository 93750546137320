import React, { useState, useEffect } from "react";
import axios from 'axios';
import config from './config';
import ChatBubble from './components/ChatBubble';
import ChatInput from './components/ChatInput';
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { About } from "./components/about";
import { Servis } from "./components/servicios/Servis";
import { Relacion } from "./components/relacion/relacion";
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import './App.css';
import 'tailwindcss/tailwind.css';



const App = () => {
	const [landingPageData, setLandingPageData] = useState({});
	useEffect(() => {
		setLandingPageData(JsonData);
	}, []);
	const [messages, setMessages] = useState([]);

	const addMessage = (message, isUser) => {
		setMessages((prevMessages) => [...prevMessages, { message, isUser }]);
	};

	const sendMessage = async (message) => {
		addMessage(message, true);

		try {
			const response = await axios.post(
				`${config.apiUrl}`,
				{
					prompt:
						'only respond to new message. previous messages: ' +
						messages.map((msg) => msg.message).join(', ') +
						', new message:' +
						message,
				},
				{
					headers: {
						'Content-Type': 'application/json',
						'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
					},
				}
			);

			const chatGptResponse = response.data;
			addMessage(chatGptResponse, false);
		} catch (error) {
			console.error('Error fetching ChatGPT response:', error);
			addMessage('Error: Unable to fetch response from ChatGPT.', false);
		}
	};

	return (
		<div className=" ">
			<Navigation />
			<Header data={landingPageData.Header} />
			<About data={landingPageData.About} />
			<Servis data={landingPageData.Servis} />
			<Relacion data={landingPageData.Relacion} />
			<Contact data={landingPageData.Contact} />
		</div>
	);
};

export default App;
