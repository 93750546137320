import React, { useState, useEffect } from "react";
import ChatBubble from './ChatBubble';
import ChatInput from './ChatInput';
import { Navigation } from "./navigation";
import axios from 'axios';
import config from '../config';
import JsonData from "../data/data.json";

export const Header = (props) => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);
  const [messages, setMessages] = useState([]);

  const addMessage = (message, isUser) => {
    setMessages((prevMessages) => [...prevMessages, { message, isUser }]);
  };

  const sendMessage = async (message) => {
    addMessage(message, true);

    try {
      const response = await axios.post(
        `${config.apiUrl}`,
        {
          prompt:
            'only respond to new message. previous messages: ' +
            messages.map((msg) => msg.message).join(', ') +
            ', new message:' +
            message,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
          },
        }
      );

      const chatGptResponse = response.data;
      addMessage(chatGptResponse, false);
    } catch (error) {
      console.error('Error fetching ChatGPT response:', error);
      addMessage('Error: Unable to fetch response from ChatGPT.', false);
    }
  };

  return (
    <header id="header">

      <div className="container-header">
        <div className="better">
          <h1>"Empowering businesses with secure, scalable blockchain solutions to unlock efficiency and transparency."</h1>
        </div>
      </div>

    </header>

  );

};
