import React from 'react';

export const Relacion = () => {
  return (
    <div id="relacion">
      <div className="container">
      <div className="linea"></div>
        <h2 className='title-relacion'>Client Relationship</h2>
        <div className="linea"></div>
        
        <div className="grid-client">
          <div className="grid-1">
            <div className="element">
            <div className='image-client' style={{backgroundImage: "url('../img/recursos/ClearCom.jpg')"}}></div>
              <div className='title-client'>
                <h2>Clear Communication</h2>
              </div>
            </div>
            <div className="element">
            <div className='image-client' style={{backgroundImage: "url('../img/recursos/Listening.jpg')"}}></div>
              <div className='title-client'>
                <h2>Active Listening</h2>
              </div>
            </div>
            <div className="element">
            <div className='image-client' style={{backgroundImage: "url('../img/recursos/Flexibility.jpg')"}}></div>
              <div className='title-client'>
                <h2>Flexibility</h2>
              </div>
            </div>
          </div>
          <div className="grid-1">
          <div className="element">
          <div className='image-client' style={{backgroundImage: "url('../img/recursos/Support.jpg')"}}></div>
              <div className='title-client'>
                <h2>Exceptional Technical Support</h2>
              </div>
          </div>
            <div className="element">
            <div className='image-client' style={{backgroundImage: "url('../img/recursos/Collaboration.jpg')"}}></div>
              <div className='title-client'>
                <h2>Collaboration</h2>
              </div>
            </div>
            <div className="element">
            <div className='image-client' style={{backgroundImage: "url('../img/recursos/Transparency.jpg')"}}></div>
              <div className='title-client'>
                <h2>Transparency</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}


