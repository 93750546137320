import React from 'react'
import services from '../img/icon-services.png'

function Card({ image, title, text }) {
  return (
    <div className='card-gen'>
      <img src={image} alt={title} />
      <h2 className='servi-title'>{title}</h2>
      <p>{text}</p>
    </div>
  )
}

export default Card;