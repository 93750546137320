import { useState } from "react";
import emailjs from '@emailjs/browser';
import React from "react";

const initialState = {
  email: '',
};
export const Contact = (props) => {
  const [{ email }, setState] = useState(initialState);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showForm, setShowForm] = useState(true); // Estado para controlar la visibilidad del formulario

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  const clearState = () => {
    setState(initialState);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    clearState();
    emailjs
      .sendForm("service_vd9dqum", "template_my3chvl", e.target, "1N262NXTCVfO7X1lN")
      .then(
        (result) => {
          console.log(result.text);
          setShowSuccessMessage(true); // Mostrar mensaje de éxito
          setShowForm(false)
          setTimeout(() => {
            setShowSuccessMessage(false); // Ocultar mensaje después de 5 segundos
            setShowForm(true);
          }, 5000);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <>
      <div className="Footer">
        <div className="info-gen">
          <div className="uno">
            <div className="info-div">
              <div>
                <img className="logonav" src="../img/svg/location-point.svg" />
              </div>
              <div className="direction">
                <h2> Registered in</h2>
                <p>759 SW FEDERAL HIGHWAY SUITE 304
                  STUART, Florida, 34994
                  United States of America</p>
              </div>
            </div>
            <div className="info-div">
              <div>
                <img className="logonav" src="../img/svg/envelope.svg" />
              </div>
              <div className="direction">
                <h2> Email Us</h2>
                <p>contact@blockchain-stories.com</p>
              </div>
            </div>
          </div>
          <div className="dos">
      <h2>Contact Us</h2>
      {/* Renderizar el formulario solo si showForm es true */}
      {showForm && (
        <form className="contact-container" onSubmit={handleSubmit}>
          <input
            type="email"
            id="email"
            name="email"
            className="contact-mail"
            placeholder="Your mail"
            style={{ padding:" 25px", color: "black" }}
            value={email}
            onChange={handleChange}
          />
          <button className="send-button">Send</button>
        </form>
      )}
      {/* Mostrar el mensaje de éxito si showSuccessMessage es true */}
      {showSuccessMessage && 
        <div style={{ 
            backgroundColor: '#dff0d8', 
            color: '#3c763d', 
            padding: '25px', 
            border: '1px solid #d6e9c6', 
            borderRadius: '5px', 
            marginTop: '10px',
            textAlign: 'center',
            fontSize: '18px'
          }} className="success-message"
        >
          Correo enviado con éxito!
        </div>
      }
      <img className="logofoot" src="../img/recursos/icon-logo.png"/>
    </div>
    <div className="img-2">
    <img className="logofoot2" src="../img/recursos/icon-logo.png"/>
    </div>
        </div>
      </div>
    </>
  );
};
