import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Card from './card/index.jsx'
import services from './img/icon-services.png'
import wallet from './img/icon-wallet.png'
import contrato from './img/icon-contracts.png'
import blockchain from './img/icon-blockchain.png'
import api from './img/icon-api.png'

const data = [
  {
    image: services,
    title: 'Consulting services',
    text: 'Offer consulting services to help clients identify areas where they can use blockchain solutions.',
  },
  {
    image: api,
    title: 'Third Party Integrations (API)',
    text: 'Offer integrations with other systems and services to improve the efficiency and functionality of blockchain solutions.',
  },
  {
    image: wallet,
    title: 'Digital wallets',
    text: 'Use secure, easy-to-use digital wallets to send and receive cryptocurrency.',
  },
  {
    image: contrato,
    title: 'Smart contracts',
    text: 'Use smart contracts to automate business processes, improve transparency, security and reduce costs.',
  },
  {
    image: blockchain,
    title: 'Blockchain applications',
    text: 'Deliver custom blockchain applications that integrate with the clients existing systems and processes.'
  },
];

const settings = {
  dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
  centerPadding: '20px',
  responsive: [
    {
      breakpoint: 1024, // Para pantallas medianas
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 768, // Para pantallas pequeñas
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

export const Servis = (props) => {
  return (
    <div id="Servis">
      <div className="container">
        <div className="linea"></div>
        <h2 className="title-about">Client Services</h2>
        <div className="linea"></div>
        <Slider {...settings}>
        {data.map((item, index) => (
          <Card
            key={index}
            image={item.image}
            title={item.title}
            text={item.text}
          />
        ))}
        </Slider>
      </div>
    </div>
  );
};

export default Servis;
