import React from "react";

export const About = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div className="linea"></div>
        <h2 className="title-about">About Us</h2>
        <div className="linea"></div>

        <div className="container-about">
          <div class="half1">
            <h2>Our Mission</h2>
            <p>
              Our mission is to provide secure and scalable blockchain
              solutions for businesses and organizations, helping them
              fully leverage the potential of blockchain technology to
              improve the efficiency, transparency and reliability of
              their operations.
            </p>
          </div>
          <div className="half2">
            <h2>Our Vision</h2>
            <p>
              To become the reference company in the blockchain solutions
              market worldwide.
              Develop a global network of partners and allies to expand
              our presence and reach in the market.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
